var admin_email = "oanapopescu93@gmail.com"
var word_bank = {
    salon: "沙龙",
    about: "关于",
    questions: "问题",
    terms_cond: "条款和条件",
    policy_privacy: "隐私政策",
    contact: "联系",
    career: "职业生涯",
    info: "信息",
    back: "返回",
    bet_type: "投注类型",
    and: "和",
    or: "或",
    i_agree_to: "我同意",

    // games
    games: "游戏",
    roulette: "轮盘",
    blackjack: "21点",
    slots: "老虎机",
    craps: "丢骰子",
    race: "赛跑",
    keno: "快乐十分",
    poker: "扑克",
    baccarat: "百家乐",
    european: "欧洲",
    american: "美式",
    reel_3: "3轮",
    reel_5: "5轮",
    poker_texas_holdem: "德州扑克",
    poker_5_card_draw: "五张牌扑克",
    responsible_gamble_request: "请负责任地游戏！",

    success: "成功",
    error: "错误",
    all_rights_reserved: "版权所有",

    cookies_modal_title: "Cookies通知",
    cookies_modal_text: "为了向您提供最相关的信息并实现最佳的系统性能，我们使用收集您游戏活动信息的Cookie。",

    sign_in: "登录",
    sign_up: "注册",
    sign_up_info: "请输入一个有效的电子邮件以通过电子邮件验证",
    signin_error: "用户不存在",
    signup_error: "注册时出现错误。请将此错误发送至 " + admin_email + " 或稍后再试。",
    signup_error_email_verification: "Your account has not been verified. Check your email.",
    email_in_use: "该电子邮件已被使用",
    user: "用户名",
    password: "密码",
    remember_me: "记住我",
    sign_in_email_empty: "电子邮件为空",
    sign_in_user_empty: "用户名为空",
    sign_in_pass_empty: "密码为空",
    sign_in_email_error: "电子邮件不正确",
    sign_in_pass_error: "密码不正确",
    signin_forgot_password: "我忘记密码",
    subtitle: "敢于追逐兔子",
    country: "国家",
    city: "城市",
    your_streak: "你连续玩了多少天",
    streak_prize_text: "如果你连续玩10天，你会得到一个奖励",

    exit_salon: "退出沙龙",
    salon_subtitle: "欢迎来到沙龙",
    exit_game: "退出游戏",

    donation: "捐赠",
    crypto: "加密货币",
    paypal: "贝宝",
    bank: "银行",
    crypto_donation_title: "通过加密货币捐赠",
    crypto_donation_text: "请将您的加密货币捐赠发送到以下地址。捐赠发送后，可能需要一些时间在区块链网络上确认。",
    paypal_donation_title: "通过贝宝捐赠",
    paypal_donation_text: "请使用以下链接通过PayPal进行捐赠。捐赠完成后，可能需要一些时间处理。",
    bank_donation_title: "通过银行转账捐赠",
    bank_donation_text: "请将您的捐赠发送到以下银行账户。捐赠转账后，可能需要一些时间反映在账户中。",
    donation_footer_text: "感谢您的慷慨！",
    donation_ukraine: "乌克兰捐赠",
    donate_with_nowpayments: "通过NOWPayments捐款",

    all: "所有",
    other: "其他",
    title: "标题",
    location: "位置",
    requirements: "要求",
    responsabilities: "责任",  
    nice_to_have: "Nice to have",
    marketing: "市场营销",   

    under_construction: "建设中",

    no_data: "无信息",
    no_bets: "您未下注",
    no_user: "未找到用户",
    no_money: "您的胡萝卜不足。您可以从市场购买。",
    no_payment_methods: "尚无付款方式",
    no_cart: "购物车为空",
    no_history: "无历史记录",
    no_jobs: "目前尚无可用职位。但请随时将您的简历发送给我们。",
    no_participation_today: "今天没有参与",
    no_order: "没有订单",
    no_selections: "选择至少一个Keno点开始游戏",
    no_match: "没有匹配",
    keno_too_many_selected: "选择了太多点",

    isMinor_title: "年龄验证",
    isMinor_text: "您是否年满18岁？",
    yes: "是",
    no: "否",
    isMinor_sign: "您因未成年而无法进入。",

    forgot_password_title: "忘记密码",
    forgot_password_text: "请输入用于注册的电子邮件地址。我们将向该地址发送您的新密码。",
    send: "发送",
    email_send: "邮件已发送。",
    email_no_send: "邮件发送失败。",
    subject: "主题",
    message: "消息",
    sending: "发送中...",

    incorrect_email: "电子邮件地址不正确",
    empty_input_subject: "主题输入为空",
    empty_input_about: "关于输入为空",
    empty_input_message: "消息输入为空",
    empty_input_agree: "您没有同意条款和隐私政策",
    account_issues: "账户问题", 
    payments_and_withdrawals: "支付和提款", 
    game_issues: "游戏问题", 
    hiring: "招聘", 

    market: "市场",
    settings: "设置",
    how_to_play: "玩法",
    logout: "登出",
    joined_the_chat: "加入了聊天",
    left_the_chat: "离开了聊天",
    buy: "购买",
    play: "游戏",
    play_whack_a_rabbit: "玩打兔子获取免费胡萝卜!",

    language: "语言",
    date_calendar: "日期",
    currency: "货币",  
    theme: "主题",
    red: "红色",
    orange: "橙色",
    yellow: "黄色",
    green: "绿色",
    blue: "蓝色",
    brown: "棕色",
    purple: "紫色",
    black: "黑色",
    grey: "灰色",
    white: "白色",
    forest_green: "森林绿",
    saphire_purple: "蓝宝石紫",
    radium_black: "镭黑",
    copper_night: "铜夜",
    
    dashboard: "仪表板",
    choose_profile_pic: "选择个人资料图片",
    animal: "动物",
    carrots: "胡萝卜",
    change_username: "更改用户名",
    change_password: "更改密码",
    buy_carrots: "购买胡萝卜",
    choose: "选择",
    change: "更改",
    profile_grey: "您需要拥有超过1000个胡萝卜才能拥有此图片",
    empty_input_change_username: "用户名不能为空",
    password_rule01: "至少一个大写英文字母",
    password_rule02: "至少一个小写英文字母",
    password_rule03: "至少一个数字",
    password_rule04: "至少一个特殊字符",
    password_rule05: "至少八个字符",

    //cart
    value: "价值 ",
    price: "价格",
    qty: "数量",
    total_price: "总价",
    cart: "购物车",
    cart_empty: "购物车为空",
    order_summary: "订单摘要",
    subtotal: "小计",
    shipping: "运费",
    promo_code: "优惠码",
    promo_discount: "折扣",
    total: "总计",
    checkout: "结算",
    apply_coupon: "使用优惠券",
    remove_all: "清空",
    continue_shopping: "继续购物",
    coupon_not_valid: "您的优惠券无效",

    //checkout"
    customer_info: "客户信息",
    name: "姓名",
    email: "电子邮件",
    phone: "电话",
    payment_info: "支付信息",
    cash_on_delivery: "货到付款",
    pay_card: "信用卡或借记卡",
    pay_paypal: "Paypal",
    pay_crypto: "加密货币",
    pay_google: "Google Pay",
    card_number: "卡号",
    month: "月份",
    year: "年份",  
    cvv: "CVV",
    bitcoin_wallet: "比特币地址",
    error_charge: "很抱歉，由于我们的错误，我们无法向您收费。我们将尽快修复此错误。",
    amount_too_low: "金额过低。",
    createSource_error: "未能将卡片附加到客户。",
    addNewCard_error: "无法创建卡片令牌。", 
    submit: "提交",
    pay: "支付",
    min_amount: "最低金额",
    fiat_equivalent: "法定货币等值",
    your_amount_in_fiat_equivalent: "您的金额的法币等值",
    amount_too_small_transaction: "金额太小，无法进行交易",
    payment_details: "支付详情",
    payment_methode: "支付方式",
    january: "一月",
    february: "二月",
    march: "三月",
    april: "四月",
    may: "五月",
    june: "六月",
    july: "七月",
    august: "八月",
    september: "九月",
    october: "十月",
    november: "十一月",
    december: "十二月",
    fill_field: "请填写此字段",
    fill_field_email: "Please fill email",
    fill_field_phone: "Please fill phone number",
    fill_field_user: "Please fill username",
    fill_field_pass: "Please fill password",
    fill_field_cv: "Please fill CV.pdf",
    fill_field_checkboxOne: "Please agree to the Terms and Conditions and Privacy Policy to complete your signup",
    validate_message_name: "这不是一个有效的姓名",
    validate_message_email: "这不是一个有效的电子邮件地址",
    validate_message_phone: "这不是一个有效的电话号码",
    validate_message_country: "这不是一个有效的国家",
    validate_message_city: "这不是一个有效的城市",
    validate_message_cardNumber: "这不是一个有效的卡号",
    validate_message_month: "这不是一个有效的月份",
    validate_message_year: "这不是一个有效的年份",
    validate_message_cvv: "这不是一个有效的CVV",
    validate_message_bitcoinAddress: "这不是一个有效的比特币地址",
    validate_message_iban: "这不是IBAN",
    continue: "继续",
    save: "保存",
    payment_success: "支付成功",
    payment_success_text: "您已完成支付",
    payment_cancel: "支付已取消",
    payment_cancel_text: "您已取消支付",
    order_info: "订单信息",
    payment_id: "支付ID",
    date: "日期",
    description: "描述",
    customer_id: "客户ID",
    items: "项目",
    amount: "金额",
    requires_payment_method: "需要支付方式",
    requires_confirmation: "需要确认",
    requires_action: "需要操作",
    processing: "处理中",
    requires_capture: "需要捕获",
    canceled: "已取消",
    succeeded: "成功",
    createNewCustomer_error: "创建客户失败。请再试一次。",
    createPaymentMethod_error: "无法创建支付方式。请检查您的卡信息。",
    attachPaymentMethod_error: "未能将支付方式附加到客户。",
    paymentIntent_error: "付款处理失败。请稍后再试。",

    // order
    order: "订单",
    orders: "订单",
    order_message: "您的订单已成功生成！",
    order_details: "订单详情",
    customer: "客户",
    payment_method: "支付方式",
    status: "状态",
    status_fullfilled: "已完成",
    status_unfullfilled: "未完成",
    order_link: "收据链接",
    order_description: "订单描述",
    order_date: "订单日期",
    postal_code: "邮政编码",
    chargeId: "ID",
    method: "方法",

    // withdraw
    withdrawal: "取款",
    withdraw: "提取",
    withdrawals: "取款记录",
    no_withdrawal: "没有取款",
    not_enough_money_withdrawal: "您没有足够的资金进行取款。",
    withdraw_instructions: "要申请提现，请填写以下表格。确保所有必填字段准确填写。选择所需的金额和货币，并提供您的全名、电话号码、电子邮件地址和用于交易的IBAN。提交后，您将收到确认电子邮件，我们的团队将处理您的请求。处理时间可能因提现方式和验证要求而有所不同。如果您有任何问题或遇到问题，请联系支持人员。",
    withdraw_success: "提现成功",
    withdraw_failed: "提现失败",
    instructions: "说明",
    min_amount_withdraw: "提款所需的最小胡萝卜数量",
    convert_carrots_rate: "胡萝卜兑换率",

    // blackjack
    start: "开始",
    hit: "要牌",
    stand: "停牌",
    double_down: "加倍下注",
    surrender: "投降",
    not_current_player: "现在不是您的回合",
    hit_explanation: "拿另一张牌增加你的手牌。",
    stand_explanation: "保留当前手牌并结束你的回合。",
    double_down_explanation: "加倍你的赌注，拿一张牌。",
    surrender_explanation: "放弃你的赌注，结束你的手牌。",
    table_1: "桌子01", 
    table_2: "桌子02", 
    table_3: "桌子03", 
    table_4: "桌子04", 
    table_5: "桌子05", 
    table_6: "桌子06", 
    table_7: "桌子07", 
    table_8: "桌子08", 
    reset: "重置",

    // race
    breed: "品种",
    delay: "延迟",
    health: "健康",
    bet: "赌注",
    place: "位置",
    place_01: "第一名",
    place_02: "第二名",
    place_03: "第三名",

    // Keno
    keno_instructions: "选择最多10个Keno号码或选择快速选择（它将为您随机选择最多10个Keno号码）",
    price_per_game: "每场游戏价格",
    no_of_games: "游戏数量",
    quick_pick: "快速选择",
    keno_prizes: "Keno奖品",
    keno_numbers_played: "玩了多少号码", 
    keno_numbers_matched: "猜对了多少号码",
    keno_win: "赢得",
    your_numbers: "您的号码",
    lucky_numbers: "幸运号码", 

    // slots
    slots_prizes: "老虎机奖品",

    // poker
    total_pot: "总奖池",
    check: "看牌",
    check_explanation: "不下注，继续游戏。",
    fold: "弃牌",
    fold_explanation: "放弃当前手牌。",
    call: "跟注",
    call_explanation: "跟上当前最高的赌注。",
    raise: "加注",
    raise_explanation: "提高当前最高赌注，迫使其他人跟注或弃牌。",
    showdown: "摊牌",
    showdown_explanation: "展示牌来确定胜者。",
    draw: "换牌",
    draw_explanation: "用新牌换掉现有的牌。",
    cancel: "取消",
    cancel_explanation: "取消之前的操作。",
    bet_explanation: "下注初始金额。",
    invalid_raise: "无效的加注",
    not_enough_money: "余额不足",
    not_enough_money_call: "资金不足，无法匹配当前最高赌注",
    small_blind: "小盲注",

    // baccarat
    player: "玩家",
    banker: "庄家",
    tie: "平局",

    // craps
    dice: "骰子",
    dices: "骰子们",
    sum: "总和",
    point: "点数",

    // results
    results: "结果",
    result: "结果",
    game: "游戏",
    game_type: "游戏类型",
    you_win: "你赢了",
    you_lose: "你输了",

    welcome: "欢迎",
    welcome_gift: "欢迎礼物",
    welcome_text: "免费赠送100胡萝卜！",
    prize: "奖品",
    prizes: "奖品",
    our_casino: "我们的赌场",
    
    //401
    page_not_found_text: "抱歉，您查找的页面不存在。可能已移动或删除，或者您输入了错误的网址。",

    // ChatBot
    chatbot: "聊天机器人",
    anonymous: "匿名",
    greetings01: "你好",
    greetings02: "我在这里回答你关于BunnyBet的所有问题。",
    error_chatbot: "我不明白你的问题。",

    // Email verification
    email_verification: "电子邮件验证",
    verifying: "验证中",
    no_token: "没有令牌",
    error_during_verification: "验证时出错",
    invalid_expired_token: "无效或过期的令牌",
    email_verify_success: "电子邮件验证成功",
    email_already_verified: "电子邮件已验证",
    email_send_validation_title: "验证您的电子邮件地址",
    email_send_validation_text: "我们已将验证邮件发送到您的收件箱。请检查您的电子邮件并点击验证链接以激活您的帐户。如果您没有看到邮件，请检查您的垃圾邮件或垃圾文件夹。谢谢！",
    email_send_validation_button: "重新发送验证邮件",
    token_is_not_verified: "该用户尚未验证。您需要重新发送验证邮件。然后，请检查您的电子邮件并点击验证链接以激活您的账户。如果未收到邮件，请检查垃圾邮件或垃圾文件夹。谢谢！",

    // Apply Job
    apply: "申请工作",

    // Newsletter
    newsletter: "新闻通讯",
    company: "公司",
    resources: "资源",
    links: "链接",
    subscribed: "已订阅",
    unsubscribed: "已退订",
    already_subscribed: "已经订阅",

    // Gambling warning
    gambling_warning_signs: "赌博问题的警告信号",
    warning_gambling_01: "借钱、卖掉所有东西甚至偷窃来获得赌博资金。",
    warning_gambling_02: "谎报在赌博上花费的时间或金钱。",
    warning_gambling_03: "隐藏用于赌博的时间或隐瞒账单和未偿债务。",
    warning_gambling_04: "花大量时间赌博、思考赌博或计划赌博。",
    warning_gambling_05: "不赌博时感到焦躁不安或易怒。",
    warning_gambling_06: "夸耀赢利，夸大胜利和/或淡化损失。",
    are_you_sure: "你确定吗？",
    are_you_sure_bets: "你确定要下注这个金额吗？",   
}

export const wordsZh = (info)=>{
    return word_bank[info]
}
