var admin_email = "oanapopescu93@gmail.com"
var word_bank = {
    salon: "Salon",
    about: "Über uns",
    questions: "Fragen",
    terms_cond: "Geschäftsbedingungen",
    policy_privacy: "Datenschutzrichtlinie",
    contact: "Kontakt",
    career: "Karriere",
    info: "Info",
    back: "Zurück",
    bet_type: "Wettart",
    and: "und",
    or: "oder",
    i_agree_to: "Ich stimme",

    // games
    games: "Spiele",
    roulette: "Roulette",
    blackjack: "Blackjack",
    slots: "Slots",
    craps: "Craps",
    race: "Rennen",
    keno: "Keno",
    poker: "Poker",
    baccarat: "Baccarat",
    european: "Europäisch",
    american: "Amerikanisch",
    reel_3: "3 Walzen",
    reel_5: "5 Walzen",
    poker_texas_holdem: "Texas hold'em",
    poker_5_card_draw: "5 Card Draw",
    responsible_gamble_request: "Bitte spielen Sie verantwortungsvoll!",

    success: "Erfolg",
    error: "Fehler",
    all_rights_reserved: "Alle Rechte vorbehalten",
    
    cookies_modal_title: "Cookie-Benachrichtigung",
    cookies_modal_text: "Um Ihnen die relevantesten Informationen und eine optimale Systemleistung bieten zu können, verwenden wir Cookies, die Informationen über Ihre Spielaktivitäten sammeln.",
    
    sign_in: "Anmelden",
    sign_up: "Registrieren",
    sign_up_info: "Bitte geben Sie eine gültige E-Mail-Adresse ein, um diese per E-Mail zu verifizieren",
    signin_error: "Der Benutzer existiert nicht",
    signup_error: "Es gab einen Fehler bei der Anmeldung. Bitte senden Sie diese Fehlermeldung an " + admin_email + " oder versuchen Sie es später erneut.",
    signup_error_email_verification: "Your account has not been verified. Check your email.",
    email_in_use: "Diese E-Mail-Adresse wurde bereits verwendet",
    user: "Benutzer",
    password: "Passwort",    
    remember_me: "Erinnere dich an mich",
    sign_in_email_empty: "E-Mail ist leer",
    sign_in_user_empty: "Benutzer ist leer",
    sign_in_pass_empty: "Passwort ist leer",
    sign_in_email_error: "E-Mail ist falsch",
    sign_in_pass_error: "Passwort ist falsch",
    signin_forgot_password: "Ich habe mein Passwort vergessen",
    subtitle: "Traue dich, den Hasen zu fangen",
    country: "Land",
    city: "Stadt",
    your_streak: "Wie viele Tage hintereinander hast du gespielt",
    streak_prize_text: "Wenn du 10 Tage hintereinander spielst, bekommst du einen Preis",
    
    exit_salon: "Salon verlassen",
    salon_subtitle: "Willkommen im Salon",
    exit_game: "Spiel verlassen",
    
    donation: "Spenden",
    crypto: "Krypto",
    paypal: "Paypal",
    bank: "Bank",
    crypto_donation_title: "Spenden via Kryptowährung",
    crypto_donation_text: "Bitte senden Sie Ihre Kryptowährungsspende an die folgenden Adressen. Sobald die Spende gesendet wurde, kann es einige Zeit dauern, bis sie im Blockchain-Netzwerk bestätigt wird.",
    paypal_donation_title: "Spenden via PayPal",
    paypal_donation_text: "Bitte verwenden Sie die folgenden Links, um eine Spende über PayPal zu tätigen. Sobald die Spende abgeschlossen ist, kann es einige Zeit dauern, bis sie verarbeitet wird.",
    bank_donation_title: "Spenden via Banküberweisung",
    bank_donation_text: "Bitte senden Sie Ihre Spende auf das folgende Bankkonto. Sobald die Spende überwiesen wurde, kann es einige Zeit dauern, bis sie im Konto angezeigt wird.",
    donation_footer_text: "Vielen Dank für Ihre Großzügigkeit!",
    donation_ukraine: "Spende für die Ukraine",
    donate_with_nowpayments: "Spenden mit NOWPayments",
    
    all: "Alle",
    other: "Andere",
    title: "Titel",
    location: "Ort",
    requirements: "Anforderungen",
    responsabilities: "Verantwortlichkeiten", 
    nice_to_have: "Nice to have",
    marketing: "Marketing",
    
    under_construction: "Im Aufbau",
    
    no_data: "Keine Informationen",
    no_bets: "Du hast keine Einsätze platziert",
    no_user: "Es wurde kein Benutzer gefunden",
    no_money: "Du hast nicht genug Karotten. Du kannst sie auf dem Markt kaufen.",
    no_payment_methods: "Keine Zahlungsmethoden vorhanden",
    no_cart: "Warenkorb ist leer",
    no_history: "Keine Historie",
    no_jobs: "Noch keine Stellen verfügbar. Aber senden Sie uns gerne Ihren Lebenslauf.",
    no_participation_today: "Heute keine Teilnahme",
    no_order: "Keine Bestellungen",
    no_selections: "Wähle mindestens einen Keno-Spot, um das Spiel zu starten",
    no_match: "Keine Übereinstimmung",
    keno_too_many_selected: "Zu viele Stellen ausgewählt",
    
    isMinor_title: "Altersüberprüfung",
    isMinor_text: "Bist du über 18 Jahre alt?",
    yes: "Ja",
    no: "Nein",
    isMinor_sign: "Du kannst nicht eintreten, weil du minderjährig bist.",
    
    forgot_password_title: "Passwort vergessen",
    forgot_password_text: "Bitte gib die E-Mail-Adresse ein, mit der du dich registriert hast. Wir senden dir dein neues Passwort an diese Adresse.",
    send: "Senden",
    email_send: "Die E-Mail wurde gesendet.",
    email_no_send: "E-Mail wurde nicht gesendet.",
    subject: "Betreff",
    message: "Nachricht",
    sending: "Senden...",
    
    incorrect_email: "Die E-Mail ist falsch",
    empty_input_subject: "Das Betreff-Feld ist leer",
    empty_input_about: "Das Über-Feld ist leer",
    empty_input_message: "Das Nachrichten-Feld ist leer",
    empty_input_agree: "Sie haben den Geschäftsbedingungen und der Datenschutzrichtlinie nicht zugestimmt",
    account_issues: "Kontoprobleme", 
    payments_and_withdrawals: "Zahlungen und Abhebungen", 
    game_issues: "Spielprobleme", 
    hiring: "Einstellung", 
    
    market: "Markt",
    settings: "Einstellungen",
    how_to_play: "Anleitung",
    logout: "Abmelden",
    joined_the_chat: "hat den Chat betreten",
    left_the_chat: "hat den Chat verlassen",
    buy: "Kaufen",
    play: "Spielen",
    whack_a_rabbit_popup: "Spiele Whack a Rabbit, um kostenlose Karotten zu bekommen!",
    
    language: "Sprache",
    date_calendar: "Datum",
    currency: "Währung", 
    theme: "Thema",
    red: "Rot",
    orange: "Orange",
    yellow: "Gelb",
    green: "Grün",
    blue: "Blau",
    brown: "Braun",
    purple: "Lila",
    black: "Schwarz",
    grey: "Grau",
    white: "Weiß",
    forest_green: "Waldgrün",
    saphire_purple: "Saphirlila",
    radium_black: "Radiumschwarz",
    copper_night: "Kupfernacht",
    
    dashboard: "Dashboard",
    choose_profile_pic: "Profilbild wählen",
    animal: "Tier",
    carrots: "Karotten",
    change_username: "Benutzernamen ändern",
    change_password: "Passwort ändern",
    buy_carrots: "Karotten kaufen",
    choose: "Wählen",
    change: "Ändern",
    profile_grey: "Um dieses Bild zu haben, benötigst du mehr als 1000 Karotten",
    empty_input_change_username: "Der Benutzername darf nicht leer sein",
    password_rule01: "Mindestens ein Großbuchstabe",
    password_rule02: "Mindestens ein Kleinbuchstabe",
    password_rule03: "Mindestens eine Ziffer",
    password_rule04: "Mindestens ein Sonderzeichen",
    password_rule05: "Mindestens acht Zeichen lang",
    
    //cart
    value: "Wert",
    price: "Preis",
    qty: "Menge",
    total_price: "Gesamtpreis",
    cart: "Warenkorb",
    cart_empty: "Warenkorb ist leer",
    order_summary: "Bestellübersicht",
    subtotal: "Zwischensumme",
    shipping: "Versand",
    promo_code: "Rabattcode",
    promo_discount: "Rabatt",
    total: "Gesamt",
    checkout: "Zur Kasse",
    apply_coupon: "Gutschein anwenden",
    remove_all: "Alles entfernen",
    continue_shopping: "Weiter einkaufen",
    coupon_not_valid: "Dein Gutschein ist ungültig",

    //checkout"
    customer_info: "Kundendaten",
    name: "Name",
    email: "E-Mail",
    phone: "Telefon",
    payment_info: "Zahlungsinformationen",
    cash_on_delivery: "Barzahlung bei Lieferung",
    pay_card: "Kredit- oder Debitkarte",
    pay_paypal: "Paypal",
    pay_crypto: "Kryptowährung",
    pay_google: "Google Pay",
    card_number: "Kartennummer",
    month: "Monat",
    year: "Jahr",  
    cvv: "CVV",
    bitcoin_wallet: "Bitcoin-Adresse",
    error_charge: "Es tut uns leid, aber wir können dich aufgrund eines Fehlers deinerseits nicht belasten. Wir werden diesen Fehler so schnell wie möglich beheben.",
    amount_too_low: "Der Betrag ist zu niedrig.",
    createSource_error: "Fehler beim Anhängen der Karte an den Kunden.",
    addNewCard_error: "Fehler beim Erstellen eines Kartentokens.",
    submit: "Absenden",
    pay: "Bezahlung",
    min_amount: "Mindestbetrag",
    fiat_equivalent: "Gegenwert in Fiatwährung",
    your_amount_in_fiat_equivalent: "Ihr Betrag in Fiat-Währung",
    amount_too_small_transaction: "Der Betrag ist zu klein, um eine Transaktion durchzuführen",
    payment_details: "Zahlungsdetails",
    payment_methode: "Zahlungsmethode",
    january: "Januar",
    february: "Februar",
    march: "März",
    april: "April",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    august: "August",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "Dezember",
    fill_field: "Bitte füllen Sie dieses Feld aus",
    fill_field_email: "Please fill email",
    fill_field_phone: "Please fill phone number",
    fill_field_user: "Please fill username",
    fill_field_pass: "Please fill password",
    fill_field_cv: "Please fill CV.pdf",
    fill_field_checkboxOne: "Please agree to the Terms and Conditions and Privacy Policy to complete your signup",
    validate_message_name: "Dies ist kein Name",
    validate_message_email: "Dies ist keine gültige E-Mail",
    validate_message_phone: "Dies ist keine gültige Telefonnummer",
    validate_message_country: "Dies ist kein gültiges Land",
    validate_message_city: "Dies ist keine gültige Stadt",
    validate_message_cardNumber: "Dies ist keine gültige Kartennummer",
    validate_message_month: "Dies ist kein gültiger Monat",
    validate_message_year: "Dies ist kein gültiges Jahr",
    validate_message_cvv: "Dies ist keine gültige CVV",
    validate_message_bitcoinAddress: "Dies ist keine gültige Bitcoin-Adresse",
    validate_message_iban: "Das ist keine IBAN",
    continue: "Weiter",
    save: "Speichern",
    payment_success: "Zahlung erfolgreich",
    payment_success_text: "Sie haben Ihre Zahlung abgeschlossen",
    payment_cancel: "Zahlung abgebrochen",
    payment_cancel_text: "Sie haben Ihre Zahlung abgebrochen",
    order_info: "Bestellinformation",
    payment_id: "Zahlungs-ID",
    date: "Datum",
    description: "Beschreibung",
    customer_id: "Kunden-ID",
    items: "Artikel",
    amount: "Betrag",
    requires_payment_method: "Zahlungsmethode erforderlich",
    requires_confirmation: "Bestätigung erforderlich",
    requires_action: "Aktion erforderlich",
    processing: "Verarbeitung",
    requires_capture: "Erfassung erforderlich",
    canceled: "Storniert",
    succeeded: "Erfolgreich",
    createNewCustomer_error: "Kunde konnte nicht erstellt werden. Bitte versuchen Sie es erneut.",
    createPaymentMethod_error: "Zahlungsmethode konnte nicht erstellt werden. Bitte überprüfen Sie Ihre Kartendaten.",
    attachPaymentMethod_error: "Zahlungsmethode konnte nicht an Kunden angehängt werden.",
    paymentIntent_error: "Zahlung konnte nicht verarbeitet werden. Bitte versuchen Sie es später erneut.",

    // order
    order: "Bestellung",
    orders: "Bestellungen", 
    order_message: "Deine Bestellung wurde erfolgreich aufgegeben!",
    order_details: "Bestelldetails",
    customer: "Kunde",
    payment_method: "Zahlungsmethode",
    status: "Status",
    status_fullfilled: "Erfüllt",
    status_unfullfilled: "Nicht erfüllt",
    order_link: "Link zur Quittung",
    order_description: "Bestellbeschreibung",
    order_date: "Bestelldatum",
    postal_code: "Postleitzahl",
    id: "ID",
    method: "Methode",
    
    // withdraw
    withdrawal: "Abhebung",
    withdraw: "Abheben",
    withdrawals: "Abhebungen",
    no_withdrawal: "Keine Abhebungen",
    not_enough_money_withdrawal: "Sie haben nicht genug Geld für eine Abhebung.",
    withdraw_instructions: "Um eine Auszahlung zu beantragen, füllen Sie bitte das folgende Formular aus. Stellen Sie sicher, dass alle Pflichtfelder korrekt ausgefüllt sind. Wählen Sie den gewünschten Betrag und die Währung aus und geben Sie Ihren vollständigen Namen, Ihre Telefonnummer, Ihre E-Mail-Adresse und Ihre IBAN für die Transaktion an. Nach dem Absenden erhalten Sie eine Bestätigungs-E-Mail, und unser Team wird Ihre Anfrage bearbeiten. Die Bearbeitungszeit kann je nach Auszahlungsmethode und den erforderlichen Überprüfungen variieren. Bei Fragen oder Problemen wenden Sie sich bitte an den Support.",
    withdraw_success: "Auszahlung erfolgreich",
    withdraw_failed: "Auszahlung fehlgeschlagen",
    instructions: "Anweisungen",
    min_amount_withdraw: "Mindestmenge an Karotten für eine Auszahlung",
    convert_carrots_rate: "Karottenumrechnungsrate",

    // blackjack
    start: "Start",
    hit: "Karte nehmen",
    stand: "Halten",
    double_down: "Double Down",
    surrender: "Aufgeben",
    not_current_player: "Du bist nicht an der Reihe",
    hit_explanation: "Nehmen Sie eine weitere Karte, um Ihre Hand zu erhöhen.",
    stand_explanation: "Behalten Sie Ihre aktuelle Hand und beenden Sie Ihren Zug.",
    double_down_explanation: "Verdoppeln Sie Ihren Einsatz, erhalten Sie eine weitere Karte.",
    surrender_explanation: "Verzichten Sie auf Ihren Einsatz, um Ihre Hand zu beenden.",
    table_1: "Tisch 01", 
    table_2: "Tisch 02", 
    table_3: "Tisch 03", 
    table_4: "Tisch 04", 
    table_5: "Tisch 05", 
    table_6: "Tisch 06", 
    table_7: "Tisch 07", 
    table_8: "Tisch 08", 
    reset: "Zurücksetzen",

    // race
    breed: "Rasse",
    delay: "Verzögerung",
    health: "Gesundheit",
    bet: "Einsatz",
    place: "Platzierung",
    place_01: "1. Platz",
    place_02: "2. Platz",
    place_03: "3. Platz",

    // Keno
    keno_instructions: "Wähle bis zu 10 Keno-Spots oder wähle Schnellauswahl (es werden maximal 10 Keno-Spots für dich zufällig ausgewählt)",
    price_per_game: "Preis pro Spiel",
    no_of_games: "Anzahl der Spiele",
    quick_pick: "Schnellauswahl",
    keno_prizes: "Keno-Preise",
    keno_numbers_played: "Anzahl der gespielten Nummern", 
    keno_numbers_matched: "Anzahl der erratenen Nummern",
    keno_win: "Gewinn",
    your_numbers: "Deine Zahlen",
    lucky_numbers: "Glückszahlen", 

    // slots
    slots_prizes: "Slot-Preise",

    // poker
    total_pot: "Gesamtpot",
    check: "Schieben",
    check_explanation: "Kein Einsatz, in der Runde bleiben.",
    fold: "Aussteigen",
    fold_explanation: "Gib deine Hand jetzt auf.",
    call: "Mitgehen",
    call_explanation: "Den aktuellen Höchsteinsatz mitgehen.",
    raise: "Erhöhen",
    raise_explanation: "Erhöhe den aktuellen Höchsteinsatz, wodurch andere gezwungen werden, ihn zu setzen oder auszusteigen.",
    showdown: "Showdown",
    showdown_explanation: "Hände zeigen, um den Gewinner zu bestimmen.",
    draw: "Tauschen",
    draw_explanation: "Karten gegen neue austauschen.",
    cancel: "Abbrechen",
    cancel_explanation: "Du möchtest keine Karten tauschen.",
    bet_explanation: "Platziere den anfänglichen Wetteinsatz.",
    invalid_raise: "Ungültige Erhöhung",
    not_enough_money: "Nicht genug Geld",
    not_enough_money_call: "Nicht genügend Geld, um den aktuellen Höchsteinsatz zu zahlen",
    small_blind: "Kleiner Blind",
    
    // baccarat
    player: "Spieler",
    banker: "Bankier",
    tie: "Unentschieden",

    // craps
    dice: "Würfel",
    dices: "Würfel",
    sum: "Summe",
    point: "Punkt",

    // results
    results: "Ergebnisse",
    result: "Ergebnis",
    game: "Spiel",
    game_type: "Spieltyp",
    you_win: "Du gewinnst",
    you_lose: "Du verlierst",

    welcome: "Willkommen",
    welcome_gift: "Willkommensgeschenk",
    welcome_text: "Kostenlos 100 Karotten!",
    prize: "Preis",
    prizes: "Preise",
    our_casino: "unser Casino",

    //401
    page_not_found_text: "Entschuldigung, die von Ihnen gesuchte Seite existiert nicht. Möglicherweise wurde sie verschoben oder gelöscht, oder Sie haben die URL falsch eingegeben.",

    // ChatBot
    chatbot: "ChatBot",
    anonymous: "Anonym",
    greetings01: "Hallo",
    greetings02: "Ich bin hier, um alle Ihre Fragen zu BunnyBet zu beantworten.",
    error_chatbot: "Ich verstehe Ihre Frage nicht.",
    
    // Email verification
    email_verification: "E-Mail-Verifizierung",
    verifying: "Überprüfung",
    no_token: "Kein Token",
    error_during_verification: "Fehler bei der Verifizierung",
    invalid_expired_token: "Ungültiger oder abgelaufener Token",
    email_verify_success: "E-Mail erfolgreich verifiziert",
    email_already_verified: "E-Mail wurde bereits verifiziert",
    email_send_validation_title: "Bestätigen Sie Ihre E-Mail-Adresse",
    email_send_validation_text: "Wir haben eine Bestätigungs-E-Mail an Ihren Posteingang gesendet. Bitte überprüfen Sie Ihre E-Mails und klicken Sie auf den Bestätigungslink, um Ihr Konto zu aktivieren. Wenn Sie die E-Mail nicht sehen, überprüfen Sie bitte Ihren Spam- oder Junk-Ordner. Vielen Dank!",
    email_send_validation_button: "Bestätigungs-E-Mail erneut senden",
    token_is_not_verified: "Dieser Benutzer wurde noch nicht verifiziert. Sie müssen die Verifizierungs-E-Mail erneut senden. Überprüfen Sie danach bitte Ihre E-Mails und klicken Sie auf den Verifizierungslink, um Ihr Konto zu aktivieren. Falls Sie die E-Mail nicht sehen, überprüfen Sie bitte Ihren Spam- oder Junk-Ordner. Vielen Dank!",

    // Apply Job
    apply: "Bewerben für den Job",

    // Newsletter
    newsletter: "Newsletter",
    company: "Unternehmen",
    resources: "Ressourcen",
    links: "Links",
    subscribed: "Abonniert",
    unsubscribed: "Abgemeldet",
    already_subscribed: "Bereits abonniert",

    // Gambling warning
    gambling_warning_signs: "Warnzeichen von Glücksspielproblemen",
    warning_gambling_01: "Geld leihen, alles verkaufen oder sogar stehlen, um Geld für Glücksspiele zu bekommen.",
    warning_gambling_02: "Lügen darüber, wie viel Zeit oder Geld für Glücksspiele ausgegeben wird.",
    warning_gambling_03: "Verstecken der Zeit, die mit Glücksspiel verbracht wird, oder das Verstecken von Rechnungen und unbezahlten Schulden.",
    warning_gambling_04: "Viel Zeit mit Glücksspiel verbringen, darüber nachdenken oder planen, zu spielen.",
    warning_gambling_05: "Unruhig oder gereizt sein, wenn man nicht spielt.",
    warning_gambling_06: "Mit Gewinnen prahlen, Gewinne übertreiben und/oder Verluste verharmlosen.",
    are_you_sure: "Bist du sicher",
    are_you_sure_bets: "Bist du sicher, dass du diesen Betrag setzen möchtest?",
}

export const wordsDe = (info)=>{
    return word_bank[info]
}